import expandRow from "@/components/Exchange/Expand";
import VuePersianDatetimePicker from "vue-persian-datetime-picker";
import HistoryOrderTable from "@/components/HistoryOrders/HistoryOrderTable/index";
import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    expandRow,
    PersianDatePicker: VuePersianDatetimePicker,
    HistoryOrderTable,
  },
  data() {
    return {
      value: false,
      styles: {
        height: "100vh",
        overflow: "auto",
        paddingBottom: "53px",
        position: "static",
        backgroundColor: "#141e2c",
      },
      drawerWidth: window.innerWidth >= 769 ? window.innerWidth >= 1000 ? "25%" : "30%" : "100%",
      orderStatusList: [],
      loading: false,
      pageSize: 10,
      pageNo: 1,
      total: 10,
      symbol: [],
      coinScales: {},
      orderTypes: [
        {
          value: "limit",
          text: this.$t("ordersHistory.limit"),
        },
        {
          value: "market",
          text: this.$t("ordersHistory.market"),
        },
        {
          value: "stop_limit",
          text: this.$t("ordersHistory.stopLimit"),
        },
        {
          value: "oco",
          text: this.$t("ordersHistory.oco"),
        },
      ],
      // orderType: "",
      formItem: {
        symbol: "",
        type: "",
        source: "",
        direction: "",
        date: "",
        status: "",
        orderType: "",
      },
      orders: [],
    };
  },
  computed: {
    ...mapGetters({
      getLang: "global/GET_LANG",
    }),
    lang() {
      return this.getLang;
    },
  },
  watch: {
    lang() {
      this.updateLangData();
    },
  },
  created() {
    this.formItem.date = new Date().getTime();
    this.setNavigation("nav-orders-history");
    this.getHistoryOrder();
    this.getSymbol();
    this.getOrderStatusList();
    this.getCoinScales();
  },
  mounted() {},
  methods: {
    ...mapActions({
      setNavigation: "global/NAVIGATE",
      getOrderStatusListService: "exchange/GET_ORDER_STATUS_LIST_SERVICE",
      getOrdersHistoryList: "exchange/GET_ORDERS_HISTORY_LIST_SERVICE",
      getSymbolsThumb: "market/GET_SYMBOL_THUMB_SERVICE",
      getFrontCoinScales: "market/GET_FRONT_COIN_SCALES_SERVICE",
    }),
    clearSelect(e, key) {
      e.stopPropagation()
      this.formItem[key] = ""
      if (key === 'orderType') {
        this.formItem.type = ""
        this.formItem.source = ""
      }
    },
    getOrderStatusList() {
      this.getOrderStatusListService().then((response) => {
        if (response.data.code === 0 && response.data.data.length > 0) {
          this.orderStatusList = response.data.data;
        }
      });
    },
    loadDataPage(data) {
      this.pageNo = data;
      this.getHistoryOrder();
    },
    handleSubmit() {
      this.pageNo = 1;
      this.getHistoryOrder();
      this.value = false;
    },
    handleClear() {
      // this.orderType = "";
      this.formItem = {
        symbol: "",
        type: "",
        source: "",
        direction: "",
        orderType: "",
        date: new Date().getTime(),
        status: "",
      };
      this.handleSubmit();
    },
    // getHistoryOrder() {
    //   this.loading = true;
    //   const { symbol, type, direction, date: rangeDate, status } = this.formItem,
    //   startTime = rangeDate[0]|| "",
    //   endTime = rangeDate[1] || "";
    //   let payload = {
    //     symbol : symbol,
    //     direction : direction,
    //     type: type,
    //     startTime: startTime,
    //     endTime: endTime,
    //     status: status,
    //     pageNo: this.pageNo,
    //     pageSize: this.pageSize
    //   };
    //   this.orders = [];
    //   ExchangeService.getAllOrderTypesHistoryByFilter(payload)
    //     .then(response => {
    //       let ordersHistoryData = response.data.content
    //       let rows = [];
    //       if (ordersHistoryData.length > 0) {
    //         this.total = response.totalElements;
    //         for (let i = 0; i < ordersHistoryData.length; i++) {
    //           let row = ordersHistoryData[i];
    //           row.price =
    //             row.type === "MARKET_PRICE"
    //               ? this.$t("exchange.marketprice")
    //               : row.price;
    //           rows.push(row);
    //         }
    //         this.orders = rows;
    //       }
    //     })
    //     .finally(() => {
    //       this.loading = false;
    //     })
    // },
    setSource(key) {
      if (key === "limit") {
        this.formItem.type = "LIMIT_PRICE";
        this.formItem.source = "MANUAL_ORDER";
      } else if (key === "market") {
        this.formItem.type = "MARKET_PRICE";
        this.formItem.source = "MANUAL_ORDER";
      } else if (key === "stop_limit") {
        this.formItem.type = "LIMIT_PRICE";
        this.formItem.source = "STOP_LIMIT_ORDER";
      } else if (key === "oco") {
        this.formItem.type = "LIMIT_PRICE";
        this.formItem.source = "OCO_ORDER";
      }
    },
    getHistoryOrder() {
      this.loading = true;
      const {
          symbol,
          type,
          source,
          direction,
          date: rangeDate,
          status,
        } = this.formItem,
        startTime = rangeDate[0] || "",
        endTime = rangeDate[1] || "";
      let payload = {
        symbol: symbol,
        direction: direction,
        type: type,
        source: source,
        startTime: startTime,
        endTime: endTime,
        status: status,
        pageNo: this.pageNo,
        pageSize: this.pageSize,
      };
      Object.keys(payload).forEach((key) => {
        if (payload[key] === "" || payload[key] === undefined) {
          delete payload[key];
        }
      });
      this.orders = [];
      this.getOrdersHistoryList(payload)
        .then((response) => {
          this.total = response.data.totalElements;
          let ordersHistoryData = response.data.content;
          let rows = [];
          if (ordersHistoryData.length > 0) {
            for (let i = 0; i < ordersHistoryData.length; i++) {
              let row = ordersHistoryData[i];
              row.price =
                row.type === "MARKET_PRICE"
                  ? this.$t("ordersHistory.marketPrice")
                  : row.price;
              rows.push(row);
            }
            this.orders = rows;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getSymbol() {
      this.getSymbolsThumb().then((response) => {
        if (response.data.length > 0) {
          this.symbol = response.data;
        }
      });
    },
    getCoinScales() {
      this.getFrontCoinScales().then((response) => {
        this.coinScales = response.data.data;
      });
    },
    updateLangData() {
      this.columns[1].title = this.$t("exchange.time");
      this.columns[2].title = this.$t("uc.finance.trade.symbol");
      this.columns[3].title = this.$t("uc.finance.trade.type");
      this.columns[4].title = this.$t("exchange.direction");
      this.columns[5].title = this.$t("exchange.price");
      this.columns[6].title = this.$t("exchange.num");
      this.columns[7].title = this.$t("exchange.traded");
      this.columns[8].title = this.$t("uc.finance.trade.turnover");
      this.columns[9].title = this.$t("exchange.status");
    },
  },
};
