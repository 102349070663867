import { mapGetters, mapActions } from "vuex";
import Loader from "@/components/UI/LoadingComp"

export default {
  name: "history-order-table",
  components: {
    Loader
  },
  props: {
    dataTable: {
      type: Array,
    },
    current: {
      type: Number,
    },
    total: {
      type: Number,
    },
    pageSize: {
      type: Number,
    },
    loading: {
      type: Boolean,
    },
  },
  data() {
    return {
      order: {},
      orderDetailsData: [],
      orderStopLimitDetailsData: [],
      preconditionDetailsData: [],
      isOrderDetailModalOpen: false,
      isPreconditionDetailModalOpen: false,
      isOrderStopLimitModalOpen: false,
      detailsBtnLoading: null,
      orderDetailColumns: [
        {
          title: this.$t("ordersHistory.amount"),
          key: "amount",
          align: "center",
          minWidth: 180,
          render: (h, params) => {
            return h("span", {}, this.numberWithCommas(params.row.amount));
          },
        },
        {
          title: this.$t("ordersHistory.price"),
          key: "price",
          align: "center",
          minWidth: 180,
          render: (h, params) => {
            return h("span", {}, this.numberWithCommas(params.row.price));
          },
        },
        {
          title: this.$t("ordersHistory.time"),
          key: "time",
          align: "center",
          minWidth: 180,
          sortable: true,
          render: (h, params) => {
            return h("span", {}, this.dateFormat(params.row.time));
          },
        },
        {
          key: "turnover",
          title: this.$t("ordersHistory.turnOver"),
          align: "center",
          minWidth: 180,
          render: (h, params) => {
            return h("span", {}, this.numberWithCommas(params.row.turnover));
          },
        },
      ],
      orderStopLimitDetailsColumns: [
        {
          title: this.$t("ordersHistory.amount"),
          key: "amount",
          align: "center",
        },
        {
          title: this.$t("ordersHistory.price"),
          key: "price",
          align: "center",
          render: (h, params) => {
            return h("span", {}, this.numberWithCommas(params.row.price));
          },
        },
        {
          title: this.$t("ordersHistory.time"),
          key: "time",
          align: "center",
          sortable: true,
          width: 180,
          render: (h, params) => {
            return h("span", {}, this.dateFormat(params.row.time));
          },
        },
        {
          title: this.$t("ordersHistory.turnOver"),
          key: "turnover",
          align: "center",
          render: (h, params) => {
            return h("span", {}, this.numberWithCommas(params.row.turnover));
          },
        },
      ],
      preconditionDetailsColumns: [
        {
          title: this.$t("ordersHistory.amount"),
          key: "amount",
          align: "center",
          render: (h, params) => {
            return h("span", {}, this.numberWithCommas(params.row.amount));
          },
        },
        {
          title: this.$t("ordersHistory.price"),
          key: "price",
          align: "center",
          render: (h, params) => {
            return h("span", {}, this.numberWithCommas(params.row.price));
          },
        },
        {
          title: this.$t("ordersHistory.time"),
          key: "time",
          align: "center",
          sortable: true,
          width: 180,
          render: (h, params) => {
            return h("span", {}, this.dateFormat(params.row.time));
          },
        },
        {
          title: this.$t("ordersHistory.turnOver"),
          key: "turnover",
          align: "center",
          render: (h, params) => {
            return h("span", {}, this.numberWithCommas(params.row.turnover));
          },
        },
      ],
      columns: [
        {
          type: "index",
          width: 50,
          align: "center",
          render: (h, params) => {
            return h(expandRow, {
              props: {
                skin: params.row.skin,
                rows: params.row.detail,
              },
            });
          },
        },
        {
          title: this.$t("ordersHistory.time"),
          key: "time",
          sortable: true,
          width: 180,
          align: "center",
          render: (h, params) => {
            return h("span", {}, this.dateFormat(params.row.time));
          },
        },
        {
          title: this.$t("ordersHistory.pairCoin"),
          width: 150,
          key: "symbol",
          align: "center",
        },
        {
          title: this.$t("ordersHistory.orderType"),
          width: 145,
          align: "center",
          render: (h, params) => {
            return h(
              "span",
              params.row.type === "MARKET_PRICE" &&
                params.row.source === "MANUAL_ORDER"
                ? this.$t("ordersHistory.marketOrder")
                : params.row.type === "LIMIT_PRICE" &&
                  params.row.source === "MANUAL_ORDER"
                ? this.$t("ordersHistory.limit")
                : params.row.source === "STOP_LIMIT_ORDER" ||
                  params.row.source === "STOP_LIMIT_CONDITION"
                ? this.$t("ordersHistory.stopLimit")
                : params.row.source === "OCO_ORDER"
                ? this.$t("ordersHistory.ocoLimit")
                : params.row.source === "OCO_STOP_LIMIT_ORDER" ||
                  params.row.source === "OCO_CONDITION"
                ? this.$t("ordersHistory.ocoStopLimit")
                : "--"
            );
          },
        },
        {
          title: this.$t("ordersHistory.tradeDirection"),
          key: "direction",
          width: 120,
          align: "center",
          render: (h, params) => {
            const row = params.row;
            const className = row.direction.toLowerCase();
            return h(
              "span",
              {
                attrs: {
                  class: className,
                },
              },
              row.direction == "BUY"
                ? this.$t("ordersHistory.buy")
                : this.$t("ordersHistory.sell")
            );
          },
        },
        {
          title: this.$t("ordersHistory.price"),
          key: "price",
          sortable: true,
          width: 160,
          align: "center",
          sortMethod: (a, b, type) => {
            if (type === "asc") {
              return this.compare(a, b);
            } else if (type === "desc") {
              return this.compare(b, a);
            }
          },
          render: (h, params) => {
            return h(
              "span",
              {
                attrs: {
                  title: params.row.price,
                },
              },
              !isNaN(params.row.price)
                ? this.numberWithCommas(
                    params.row.price,
                    this.getPairCoinScales[params.row.symbol].frontBaseCoinScale
                  )
                : params.row.price
            );
          },
        },
        {
          title: this.$t("ordersHistory.amount"),
          key: "amount",
          sortable: true,
          width: 170,
          align: "center",
          render: (h, params) => {
            return h("div", [
              h(
                "span",
                {
                  attrs: {
                    title: params.row.amount,
                  },
                },
                this.numberWithCommas(
                  params.row.amount,
                  this.getPairCoinScales[params.row.symbol].frontCoinScale
                )
              ),
              h(
                "span",
                {
                  style: {
                    fontSize: "9px",
                    marginLeft: "3px",
                  },
                },
                params.row.type === "MARKET_PRICE"
                  ? params.row.direction === "BUY"
                    ? params.row.baseSymbol
                    : params.row.coinSymbol
                  : params.row.coinSymbol
              ),
            ]);
          },
        },
        {
          title: this.$t("ordersHistory.tradedAmount"),
          key: "tradedAmount",
          sortable: true,
          width: 180,
          align: "center",
          render: (h, params) => {
            return h("div", [
              h(
                "span",
                {
                  attrs: {
                    title: params.row.tradedAmount,
                  },
                },
                this.numberWithCommas(
                  params.row.tradedAmount,
                  this.getPairCoinScales[params.row.symbol].frontCoinScale
                )
              ),
              h(
                "span",
                {
                  style: {
                    fontSize: "9px",
                    marginLeft: "3px",
                  },
                },
                params.row.coinSymbol
              ),
            ]);
          },
        },
        {
          title: this.$t("ordersHistory.averageTradePrice"),
          sortable: true,
          key: "averagePrice",
          width: 180,
          align: "center",
          render: (h, params) => {
            return h(
              "span",
              this.numberWithCommas(
                params.row.averagePrice,
                this.getPairCoinScales[params.row.symbol].frontBaseCoinScale
              )
            );
          },
        },
        {
          title: this.$t("ordersHistory.turnOver"),
          key: "turnover",
          sortable: true,
          width: 200,
          align: "center",
          render: (h, params) => {
            return h("div", [
              h(
                "span",
                {
                  attrs: {
                    title: params.row.turnover,
                  },
                },
                this.numberWithCommas(
                  params.row.turnover,
                  this.getPairCoinScales[params.row.symbol].frontBaseCoinScale
                )
              ),
              h(
                "span",
                {
                  style: {
                    fontSize: "9px",
                    marginLeft: "3px",
                  },
                },
                params.row.baseSymbol
              ),
            ]);
          },
        },
        {
          title: this.$t("ordersHistory.status"),
          key: "status",
          width: 150,
          align: "center",
          render: (h, params) => {
            const status = params.row.status;
            if (status == "COMPLETED") {
              return h(
                "span",
                {
                  style: {
                    color: "#f0a70a",
                  },
                },
                this.$t("ordersHistory.finished")
              );
            } else if (status == "CANCELED") {
              return h(
                "span",
                {
                  style: {
                    color: "#f15057",
                  },
                },
                this.$t("ordersHistory.canceled")
              );
            } else if (status == "OVERTIMED") {
              return h(
                "span",
                {
                  style: {
                    color: "#f0a70a",
                  },
                },
                this.$t("ordersHistory.expired")
              );
            } else {
              return h("span", {}, "--");
            }
          },
        },
        {
          title: this.$t("ordersHistory.refCode"),
          key: "trackingCode",
          width: 200,
          align: "center",
          render(h, params) {
            return h("div", params.row.trackingCode);
          },
        },
        {
          title: this.$t("ordersHistory.operation"),
          key: "operation",
          slot: "operation",
          width: 180,
          align: "center",
          // render: (h, params) => {
          //   let actions = [];
          //   actions.push(
          //     h(
          //       "Button",
          //       {
          //         props: {
          //           type: "text",
          //           size: "small",
          //         },
          //         style: {
          //           border: "1px solid #f0ac19",
          //           color: "#f1ac19",
          //           // padding: "3px 10px",
          //           // "line-height": "1.2",
          //           "border-radius": "0",
          //           fontSize: "12px",
          //         },
          //         on: {
          //           click: () => {
          //             if (
          //               params.row.source === "MANUAL_ORDER" ||
          //               params.row.source === "OCO_ORDER"
          //             ) {
          //               this.getOrderDetails(params.row.orderId);
          //             } else if (
          //               params.row.source === "STOP_LIMIT_ORDER" ||
          //               params.row.source === "OCO_STOP_LIMIT_ORDER"
          //             ) {
          //               this.getStopLimitOrderDetail({
          //                 id: params.row.orderId,
          //               });
          //             }
          //           },
          //         },
          //       },
          //       this.$t("ordersHistory.details")
          //     )
          //   );
          //   return h(
          //     "div",
          //     {
          //       style: {
          //         display: "flex",
          //         justifyContent: "space-around",
          //       },
          //     },
          //     actions
          //   );
          // },
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      getPairCoinScales: "market/GET_PAIR_COIN_SCALES",
    }),
  },
  methods: {
    ...mapActions({
      getPreconditionOrderDetailByOrderID:
        "exchange/GET_PRECONDITION_ORDER_DETAIL_BY_ORDER_ID_SERVICE",
      getOrderDetailsService: "exchange/GET_ORDER_DETAILS_SERVICE",
    }),
    getDetails(data) {
      if (this.detailsBtnLoading !== null) return;
      if (
          data.source === "MANUAL_ORDER" ||
          data.source === "OCO_ORDER"
      ) {
        this.getOrderDetails(data.orderId);
      } else if (
          data.source === "STOP_LIMIT_ORDER" ||
          data.source === "OCO_STOP_LIMIT_ORDER"
      ) {
        this.getStopLimitOrderDetail({
          id: data.orderId,
        });
      }
    },
    dateFormat(tick) {
      return this.$options.filters.dateFormat(tick);
    },
    changePageNumber(pageIndex) {
      this.$emit("changePage", pageIndex);
    },
    getStopLimitOrderDetail(orderId) {
      this.detailsBtnLoading = orderId;
      this.getPreconditionOrderDetailByOrderID(orderId).then((response) => {
        this.orderStopLimitDetailsData = response.data.data;
        this.isOrderStopLimitModalOpen = true;
      }).finally(() => {
        this.detailsBtnLoading = null;
      })
    },
    // getStopLimitPreconditionDetail(preconditionID) {
    //   ExchangeService.getPreconditionOrderDetailByID(preconditionID)
    //       .then(response => {
    //         console.log(response.data.orders)
    //         this.preconditionDetailsData = response.data
    //         this.isPreconditionDetailModalOpen = true;
    //       })
    // },
    getOrderDetails(orderID) {
      this.detailsBtnLoading = orderID;
      this.getOrderDetailsService(orderID).then((response) => {
        this.orderDetailsData = response.data.data.detail;
        this.order = response.data.data.order;
        this.isOrderDetailModalOpen = true;
      }).finally(() => {
        this.detailsBtnLoading = null;
      })
    },
    closeOrderDetailModal() {
      this.isOrderDetailModalOpen = false;
    },
  },
};
